import React, { Dispatch, FC, memo, SetStateAction, useCallback, useEffect, useRef, useState } from "react"
import * as styles from "./page1.module.scss"
import Typography from "../../component-system/typography"
import { TFunction, useTranslation } from "react-i18next"
import { useCustomIntersection } from "../../hooks/useCustomIntersection"
import classNames from "classnames"

interface Props {
  lan: string
  t: TFunction
  setLang: Dispatch<SetStateAction<string>>
  offsetAbout: number
  offsetService: number
  offsetContact: number
  inner: boolean
}

const Page1: FC<Props> = memo(({ lan, t, setLang, inner, ...props }) => {
  const { i18n } = useTranslation()
  const typoRef = useRef<HTMLDivElement | null>(null)

  const [aniState, setAniState] = useState<{
    typeAnimation: boolean | string
  }>({
    typeAnimation: false
  })


  const handleChangeLanguage = useCallback((e: React.MouseEvent) => {
    e.currentTarget.textContent === "KOR" ? i18n.changeLanguage("ko") : i18n.changeLanguage("en")
    e.currentTarget.textContent === "KOR" ? setLang("ko") : setLang("en")
  }, [lan])

  const clickAbout = () => {
    window.scrollTo({ top: props.offsetAbout, behavior: "smooth" })
  }

  const clickService = () => {
    window.scrollTo({ top: props.offsetService, behavior: "smooth" })
  }

  const clickContact = () => {
    window.scrollTo({ top: props.offsetContact, behavior: "smooth" })
  }

  const typoIntersection = useCustomIntersection(typoRef, { threshold: 1 })

  useEffect(() => {
    if (!typoIntersection.isInterSection) {
      return
    }

    if (!aniState.typeAnimation) {
      setAniState({ typeAnimation: typoIntersection.isInterSection.isIntersecting })
    } else {
      setAniState({ typeAnimation: "finish" })
    }

  }, [typoIntersection.isInterSection])


  return (
    <section className={styles.page1}>
      <header>
        {/*<div></div>*/}
        <div className={styles.logo}>
          <img alt="" src={"images/logo.svg"} />
        </div>
        <div className={styles.navigation}>
          <ul className={lan === "ko" ? styles.ko : styles.en}>
            <li onClick={clickAbout}>
              about
            </li>
            <li onClick={clickService}>
              service
            </li>
            <li onClick={clickContact}>
              contact
            </li>
          </ul>
          <ul>
            <li onClick={handleChangeLanguage} style={{ color: lan === "ko" ? "white" : "black" }}>KOR</li>
            <li onClick={handleChangeLanguage} style={{ color: lan === "en" ? "white" : "black" }}>ENG</li>
          </ul>
        </div>
      </header>
      <div className={
        classNames(
          styles.typo,
          { [styles.animation]: aniState.typeAnimation === true || "finish" }
        )}
           ref={typoRef}>
        <Typography type={"h1"} className={lan === "ko" ? styles.introKo : styles.introEn}>
          {t("intro")}
        </Typography>
        <Typography type={"h2"} className={lan === "ko" ? styles.introKo2 : styles.introEn2}>
          Kubee
        </Typography>
      </div>
    </section>
  )
})

export default Page1