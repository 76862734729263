import React, { FC, memo, ReactChild, ReactNode, useMemo } from "react"
import * as styles from "./Layout.module.scss"

export interface LayoutProps {
  lan: string
  children: ReactNode
}

const Layout: FC<LayoutProps> = memo(({ lan, children }) => {
  const style = useMemo(() => {
    return {}
  }, [lan])
  return (
    <div className={styles.Layout}>
      {children}
    </div>
  )
})

export default Layout