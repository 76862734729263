import "../styles/index.scss"
import * as React from "react"
import { FC, memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { PageProps } from "gatsby"
import Page1 from "../components/page1"
import Page2 from "../components/page2"
import Page3 from "../components/page3"
import Page4 from "../components/page4"
import Page5 from "../components/page5"
import Page6 from "../components/page6"
import Footer from "../components/Footer/footer"
import { I18nextProvider, useTranslation } from "react-i18next"
import i18n from "../i18n/i18n"
import Header from "../Header"
import Layout from "../component-system/Layout/Layout"

interface Props extends PageProps {

}

const index: FC<Props> = memo(({ location }) => {
  const [lan, setLang] = useState<string>("ko")
  const [init, setInit] = useState<boolean>(false)
  const [inner, setInner] = useState<boolean>(false)
  const { t } = useTranslation()

  const aboutRef = useRef<null | HTMLTableSectionElement>(null)
  const serviceRef = useRef<null | HTMLDivElement>(null)
  const contactRef = useRef<null | HTMLDivElement>(null)

  const [height, setHeight] = useState<{
    about: number,
    service: number,
    contact: number
  }>({
    about: 0,
    service: 0,
    contact: 0
  })


  useEffect(() => {
    const userLanguage: string = window.navigator.language
    if (userLanguage.toLowerCase() === "ko-kr") {
      i18n.changeLanguage("ko")
      setLang("ko")
      setInit(true)
    } else {
      i18n.changeLanguage("en")
      setLang("en")
      setInit(true)
    }
  }, [])


  const resize = useCallback(() => {
    if (!aboutRef.current || !serviceRef.current || !contactRef.current) {
      return
    }


    setHeight({
      about: aboutRef.current?.offsetTop,
      service: serviceRef.current?.offsetTop,
      contact: contactRef.current?.offsetTop
    })
  }, [aboutRef, serviceRef, contactRef])


  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      if (document.body.clientWidth <= 720) {
        setInner(true)
      } else {
        setInner(false)
      }
    })
  }, [])

  useEffect(() => {
    window.addEventListener("resize", resize)
    if (document.body.clientWidth <= 720) {
      setInner(true)
    } else {
      setInner(false)
    }
  }, [])

  useEffect(() => {
    if (!aboutRef.current || !serviceRef.current || !contactRef.current) {
      return
    }

    setHeight({
      about: aboutRef.current?.offsetTop,
      service: serviceRef.current?.offsetTop,
      contact: contactRef.current?.offsetTop
    })
  }, [aboutRef, serviceRef, contactRef])


  return (
    <I18nextProvider i18n={i18n}>
      <Layout lan={lan}>
        <Header />
        <div className="page1">
          <img alt="랜딩이미지" className="landingImg" />
          <Page1
            lan={lan}
            t={t}
            setLang={setLang}
            offsetAbout={height.about}
            offsetService={height.service}
            offsetContact={height.contact}
            inner={inner}
          />
        </div>
        <Page2 t={t} lan={lan} ref={aboutRef} inner={inner} />
        <Page3 t={t} lan={lan} ref={serviceRef} inner={inner} />
        <Page4 t={t} lan={lan} inner={inner} />
        <Page5 t={t} lan={lan} inner={inner} />
        <Page6 t={t} lan={lan} ref={contactRef} />
        <Footer lan={lan} t={t} setLang={setLang} inner={inner} />
      </Layout>
    </I18nextProvider>
  )
})

export default index