import classNames from "classnames"
import React, { FC, memo, ReactNode } from "react"
import * as styles from "./typography.module.scss"


interface Props {
  type: "h1" | "h2" | "h3" | "h4" | "h5" | "h6",
  children: ReactNode
  className?: string
}

const Typography: FC<Props> = memo(({ type, children, className }) => {
  return (
    React.createElement(
      type,
      { className: classNames(styles.typography, className) },
      children
    )
  )
})

export default Typography