// extracted by mini-css-extract-plugin
export var page4 = "page4-module--page4--TNltZ";
export var animation = "page4-module--animation--hdl8F";
export var container = "page4-module--container--ukQCs";
export var typo = "page4-module--typo--ajb5j";
export var h1En = "page4-module--h1En--zGkSE";
export var h2ko = "page4-module--h2ko--OOc5r";
export var h2en = "page4-module--h2en--zZ14h";
export var ko2 = "page4-module--ko2--Muxm7";
export var en2 = "page4-module--en2--lNH9a";
export var button = "page4-module--button--ZLKbo";
export var mediaButton = "page4-module--mediaButton--vls+7";
export var en = "page4-module--en--yNa8+";
export var divider = "page4-module--divider--kk3kZ";