import React, { FC, memo, useEffect, useRef, useState } from "react"
import * as styles from "./page4.module.scss"
import Typography from "../../component-system/typography"
import { TFunction, useTranslation } from "react-i18next"
import classNames from "classnames"
import { useCustomIntersection } from "../../hooks/useCustomIntersection"

interface Props {
  lan: string
  t: TFunction
  inner: boolean
}

const Page4: FC<Props> = memo(({ lan, t, inner }) => {

  const target = useRef<HTMLDivElement | null>(null)
  const intersection = useCustomIntersection(target, { threshold: 0.2 })

  const [ani, setAni] = useState<boolean | string>(false)

  useEffect(() => {
    if (!intersection.isInterSection) {
      return
    }

    if (!ani) {
      setAni(intersection.isInterSection.isIntersecting)
    } else {
      setAni("finish")
    }

  }, [intersection.isInterSection])

  return (
    <div className={styles.page4}>
      <div className={styles.container}>
        <section className={classNames({ [styles.animation]: ani === true }, { [styles.animation]: ani === "finish" })}
                 ref={target}>
          <div className={styles.typo}>
            <Typography type={"h1"} className={lan === "ko" ? styles.h1Ko : styles.h1En}>
              {t("service5")}
            </Typography>
            <Typography type={"h2"} className={lan === "ko" ? styles.h2ko : styles.h2en}>
              {!inner ? t("service6") : t("service6_inner")}
            </Typography>
            <a href="https://collection.kubee.cc/eql" target="_blank">
              <div className={classNames(styles.button, lan === "ko" ? styles.ko2 : styles.en2)}>
                <span>
                {t("service8")}
                </span>
              </div>
            </a>
          </div>
          <div>
            <img src={"images/eqlLanding.png"} alt="eql이미지" />
          </div>
          <a href="https://collection.kubee.cc/eql" target="_blank">
            <button className={classNames(styles.mediaButton, lan === "ko" ? styles.ko : styles.en)}>
              {t("service8")}
            </button>
          </a>
          <div className={styles.divider} />
        </section>
      </div>
    </div>
  )
})

export default Page4