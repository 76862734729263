import React, { FC, memo, useEffect, useRef, useState } from "react"
import * as styles from "./page6.module.scss"
import Typography from "../../component-system/typography"
import { TFunction, useTranslation } from "react-i18next"
import classNames from "classnames"
import { useCustomIntersection } from "../../hooks/useCustomIntersection"


interface Props {
  ref: React.MutableRefObject<HTMLDivElement>
  lan: string
  t: TFunction
}

const Page6 = React.forwardRef<HTMLDivElement, Props>(({ lan, t }, ref) => {

  const target = useRef<HTMLDivElement | null>(null)
  const intersection = useCustomIntersection(target, { threshold: 0.2 })

  const [ani, setAni] = useState<boolean | string>(false)

  const items: { [key: string]: { content: string[] } } = {
    personal: {
      content: ["Custom 3D viewer", t("contact2"), t("contact3")]
    },
    business: {
      content: ["Custom 3D viewer", t("contact2"), t("contact3"), t("contact4"), t("contact5")]
    }
  }

  const renderItems = (target: string) => {
    return items[target].content.map(e => {
      return (
        <li key={e}>
          <div>
            <img src="images/check.png" alt="체크이미지" />
          </div>
          <span className={lan === "ko" ? styles.ko : styles.en}>{e}</span>
        </li>
      )
    })
  }


  useEffect(() => {
    if (!intersection.isInterSection) {
      return
    }

    if (!ani) {
      setAni(intersection.isInterSection.isIntersecting)
    } else {
      setAni("finish")
    }

  }, [intersection.isInterSection])

  return (
    <div className={styles.page6} ref={ref}>
      <div ref={target}
           className={classNames(styles.target, { [styles.animation]: ani === true }, { [styles.animation]: ani === "finish" })}>
        <div>
          <Typography type={"h1"}>
            Contact
          </Typography>
          <Typography type={"h2"} className={lan === "ko" ? styles.ko : styles.en}>
            {t("contact1")}
          </Typography>
          <Typography type={"h3"}>
            official@we-ar.kr
          </Typography>
        </div>
        <div>
          <section>
            {/*<Typography type={"h1"}>*/}
            {/*  Personal plan*/}
            {/*</Typography>*/}
            {/*<div className={styles.divider} />*/}
            {/*<ul className={classNames(styles.body1, lan === "ko" ? styles.ko : styles.en)}>*/}
            {/*  {renderItems("personal")}*/}
            {/*</ul>*/}
            {/*<div className={styles.button}>*/}
            {/*  <span className={lan === "ko" ? styles.ko2 : styles.en2}>*/}
            {/*  {t("free")}*/}
            {/*  </span>*/}
            {/*</div>*/}
            <iframe width="100%" height="500" src="https://viewer.kubee.cc/V18EpP5" data-gtm-yt-inspected-14="true" data-gtm-yt-inspected-30716377_92="true"></iframe>
          </section>
          <section>
            {/*<Typography type={"h1"}>*/}
            {/*  Business plan*/}
            {/*</Typography>*/}
            {/*<div className={styles.divider} />*/}
            {/*<ul className={styles.body2}>*/}
            {/*  {renderItems("business")}*/}
            {/*</ul>*/}
            {/*<div className={styles.button}>*/}
            {/*  <span className={lan === "ko" ? styles.ko : styles.en}>*/}
            {/*  $499*/}
            {/*  </span>*/}
            {/*</div>*/}
            <iframe width="100%" height="500" src="https://viewer.kubee.cc/lpY3bqZ" data-gtm-yt-inspected-14="true" data-gtm-yt-inspected-30716377_92="true"></iframe>
          </section>
        </div>
        <div className={classNames(styles.notification, lan === "ko" ? styles.ko : styles.enNo)}>
          {t("contact6")}
        </div>
      </div>
    </div>
  )
})

export default Page6