import React, { memo } from "react"
import { Helmet } from "react-helmet"

const Header = memo(() => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      {/*<meta content="yes" name="apple-mobile-web-app-capable" />*/}
      {/*<meta content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"*/}
      {/*      name="viewport" />*/}
      {/*<meta name="viewport" content="user-scalable=0;" />*/}
      {/*<meta name="viewport" content="width=device-width" />*/}

      <link rel="icon" href="images/kubee_favicon_1.ico" />
      <title>Kubee</title>
      <link rel="canonical" href="https://landing.kubee.cc" />


      <meta content="Kubee | 영상 공유처럼 간편한 WebAR 솔루션" name="title" />
      <meta name="description" content="큐비(Kubee)로 만나기 어려운 고객에게 AR로 제품 체험을 유도하고, 새로운 경험을 제공해보세요." />
      <meta name="keywords" content="웹사이트 AR, web AR, 웹사이트 증강현실, AR, AR광고, 가구 AR, AR 마케팅, 증강현실 마케팅, 메타버스" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Kubee | 영상 공유처럼 간편한 WebAR 솔루션" />
      <meta property="og:url" content="https://landing.kubee.cc" />
      <meta property="og:site_name" content="큐비(Kubee)" />
      <meta property="og:image" content="images/kubee_og.jpg" />
      <meta property="og:description" content="큐비(Kubee)로 만나기 어려운 고객에게 AR로 제품 체험을 유도하고, 새로운 경험을 제공해보세요." />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content="Kubee | 영상 공유처럼 간편한 WebAR 솔루션" />
      <meta name="twitter:description" content="큐비(Kubee)로 만나기 어려운 고객에게 AR로 제품 체험을 유도하고, 새로운 경험을 제공해보세요." />
      <meta name="twitter:image" content="images/kubee_og.jpg" />
    </Helmet>
  )
})

export default Header