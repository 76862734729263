import React, { FC, memo, useEffect, useRef, useState } from "react"
import * as styles from "./page5.module.scss"
import Typography from "../../component-system/typography"
import { TFunction, useTranslation } from "react-i18next"
import { useCustomIntersection } from "../../hooks/useCustomIntersection"
import classNames from "classnames"

interface Props {
  lan: string
  t: TFunction
  inner: boolean
}

const Page5: FC<Props> = memo(({ lan, t, inner }) => {
  const target = useRef<HTMLDivElement | null>(null)
  const intersection = useCustomIntersection(target, { threshold: 0.3 })

  const [ani, setAni] = useState<boolean | string>(false)

  useEffect(() => {
    if (!intersection.isInterSection) {
      return
    }

    if (!ani) {
      setAni(intersection.isInterSection.isIntersecting)
    } else {
      setAni("finish")
    }

  }, [intersection.isInterSection])

  return (
    <div className={styles.page5}>
      <div
        className={styles.container}>
        <section
          className={classNames({ [styles.animation]: ani === true }, { [styles.animation]: ani === "finish" })}
          ref={target}>
          <div>
            <img src={"images/page5.png"} alt="" />
          </div>
          <div>
            <Typography type={"h1"}>
              3D Viewer & AR
            </Typography>
            <Typography type={"h2"} className={lan === "ko" ? styles.ko : styles.en}>
              {!inner ? t("service9") : t("service9_inner")}
            </Typography>
          </div>
        </section>
      </div>
    </div>
  )
})

export default Page5