import React, { Dispatch, FC, memo, SetStateAction, useCallback, useState } from "react"
import * as styles from "./DropBox.module.scss"
import { useTranslation } from "react-i18next"

export interface DropBoxProps {
  setLang: Dispatch<SetStateAction<string>>
  lan: string
}

const DropBox: FC<DropBoxProps> = memo(({ lan, setLang }) => {
  const [click, setClick] = useState<boolean>(false)
  const { i18n } = useTranslation()

  const handleClick = useCallback((e: React.MouseEvent) => {
    if (e.currentTarget.textContent === "KOREAN") {
      i18n.changeLanguage("ko")
      setLang("ko")
    } else {
      i18n.changeLanguage("en")
      setLang("en")
    }
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [click])

  const handleChangeState = useCallback((e: React.MouseEvent) => {
    setClick(!click)
  }, [click])

  return (
    <div className={styles.DropBox} onClick={handleChangeState}>
      <section className={styles.button}>
        <span>{lan === "ko" ? "KOREAN" : "ENGLISH"}</span>
        <img src="" alt="" className={styles.arrow} />
      </section>
      <ul style={{ height: click ? 56 : 0 }}>
        <li onClick={handleClick}>
          <span>KOREAN</span>
        </li>
        <li onClick={handleClick}>
          <span>ENGLISH</span>
        </li>
      </ul>
    </div>
  )
})

export default DropBox