// extracted by mini-css-extract-plugin
export var page6 = "page6-module--page6--mFnsO";
export var animation = "page6-module--animation--v0M6Z";
export var target = "page6-module--target--AHscZ";
export var en = "page6-module--en--Ujukv";
export var divider = "page6-module--divider--hbzKe";
export var button = "page6-module--button--Iqrzp";
export var ko2 = "page6-module--ko2--bGfGu";
export var ko = "page6-module--ko--WN9c7";
export var notification = "page6-module--notification--+nDrp";
export var enNo = "page6-module--enNo--t7Gpk";