import React, { Dispatch, FC, memo, SetStateAction } from "react"
import * as styles from "./Footer.module.scss"
import DropBox from "../DropBox/DropBox"
import { TFunction, useTranslation } from "react-i18next"

export interface FooterProps {
  lan: string
  t: TFunction
  setLang: Dispatch<SetStateAction<string>>
  inner: boolean
}

const Footer: FC<FooterProps> = (({ lan, t, setLang, inner }) => {


  return (
    <div className={styles.Footer}>
      <div className={styles.container}>
        <div>
          <div>
            <img src="images/we-ar.svg" alt="위에이알 로고" />
          </div>
          <div className={styles.copyRight}>
            Copyright © WE-AR All rights reserved.
          </div>
        </div>
        <div className={styles.meta}>
          <span>
            official@we-ar.kr
          </span>
          <div>
            <ul>
              <li>information</li>
              <li className={lan === "ko" ? styles.ko : styles.en}>
                {t("contact7")}
              </li>
            </ul>
            <ul>
              <li>office</li>
              <li className={lan === "ko" ? styles.ko : styles.en}>
                {t("contact9")}
              </li>
            </ul>
            <ul>
              <li>social</li>
              <li className={lan === "ko" ? styles.ko : styles.en}>{t("contact10")}</li>
              <li className={lan === "ko" ? styles.ko : styles.en}>{t("contact11")}</li>
              <li className={lan === "ko" ? styles.ko : styles.en}>{t("contact12")}</li>
              <li className={lan === "ko" ? styles.ko : styles.en}>{t("contact13")}</li>
            </ul>
          </div>
        </div>
        <div className={styles.media}>
          <DropBox setLang={setLang} lan={lan} />
          <span>Copyright ©  WE-AR All rights reserved.</span>
        </div>
      </div>
    </div>
  )
})

export default Footer