import React, { FC, memo, useEffect, useMemo, useRef, useState } from "react"
import * as styles from "./page3.module.scss"
import Typography from "../../component-system/typography"
import classNames from "classnames"
import { TFunction, useTranslation } from "react-i18next"
import { useCustomIntersection } from "../../hooks/useCustomIntersection"

interface Props {
  ref: React.MutableRefObject<HTMLDivElement>
  lan: string
  t: TFunction
  inner: boolean
}

const Page3 = React.forwardRef<HTMLDivElement, Props>(({ lan, t }, ref) => {
  const target = useRef<HTMLDivElement | null>(null)
  const intersection = useCustomIntersection(target, { threshold: 0.2 })

  const [ani, setAni] = useState<boolean | string>(false)

  const stepList = {
    type1: {
      step: "STEP 01",
      content: "kubee에서 3D 에셋을 업로드 하세요.",
      src: "images/laptop_illustration.svg"
    },
    type2: {
      step: "STEP 02",
      content: "공유 가능한 링크를 단 10초만에 생성하세요.",
      src: "images/Frame.svg"
    },
    type3: {
      step: "STEP 03",
      content: "링크를 웹에 삽입해 소비자들에게 AR 경험을 공유하세요.",
      src: "images/Frame2.svg"
    }
  }

  useEffect(() => {
    if (!intersection.isInterSection) {
      return
    }

    if (!ani) {
      setAni(intersection.isInterSection.isIntersecting)
    } else {
      setAni("finish")
    }

  }, [intersection.isInterSection])

  const renderSteps = useMemo(() => {
    return Object.values(stepList).map((e, i) => {

      return (
        <li className={classNames(styles.stepItem, lan === "ko" ? styles[`liK_${i}`] : styles[`liE_${i}`])}
            key={e.step}>
          <div>
            <span>{e.step}</span>
          </div>
          <Typography
            type={"h3"}
            className={
              classNames(
                styles[`stepContent${i}`],
                styles.stepContent, lan === "ko" ? styles[`koh3_${i}`] : styles[`enh3_${i}`],
                lan === "ko" ? styles.ko : styles.en
              )
            }
          >
            {t(`service${i + 2}`)}
          </Typography>
          <div className={classNames(styles[`image${i}`], styles.image)}>
            <img src={e.src} alt="" />
          </div>
        </li>
      )
    })
  }, [lan])

  return (
    <div className={styles.page3} ref={ref}>
      <div className={styles.container}>
        <div
          className={classNames(styles.ani, { [styles.animation]: ani === true }, { [styles.animation]: ani === "finish" })}
          ref={target}>
          <Typography type={"h1"}>
            Services
          </Typography>
          <Typography type={"h2"} className={lan === "ko" ? styles.ko : styles.en}>
            {t("service1")}
          </Typography>
          <ul className={styles.box}>
            {renderSteps}
          </ul>
        </div>
        <div className={styles.divider} />
      </div>
    </div>
  )
})
export default Page3