import React, { FC, memo, useEffect, useMemo, useRef, useState } from "react"
import * as styles from "./page2.module.scss"
import Typography from "../../component-system/typography"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { TFunction } from "react-i18next"
import { useCustomIntersection } from "../../hooks/useCustomIntersection"

interface Props {
  ref: React.MutableRefObject<any>
  lan: string
  t: TFunction
  inner: boolean
}

const Page2 = React.forwardRef<HTMLTableSectionElement, Props>(({ lan, t, inner }, ref) => {
  const typo1Ref = useRef<HTMLDivElement | null>(null)
  const typo2Ref = useRef<HTMLDivElement | null>(null)

  const typo1Intersection = useCustomIntersection(typo1Ref, { threshold: 0.2 })
  const typo2Intersection = useCustomIntersection(typo2Ref, { threshold: 0.2 })

  const [ani1, setAni1] = useState<boolean | string>(false)
  const [ani2, setAni2] = useState<boolean | string>(false)


  const partners = {
    페이스북: { src: "facebook_logo.svg" },
    현대카드: { src: "hyundaicard_logo.svg" },
    삼성: { src: "samsung_logo.svg" },
    카카오: { src: "kakao_logo.svg" },
    yg: { src: "YG_logo.svg" },
    cj: { src: "cjenm_logo.svg" },
    티빙: { src: "tving_logo.svg" },
    그린피스: { src: "greenpeace_logo.svg" },
    historyhoo: { src: "historyhoo_logo.svg" },
    엘지: { src: "lg_logo.svg" }
  }

  const renderPartners = useMemo(() => {
    return Object.entries(partners).map((e, i) => {
      return (
        <li className={styles.partnersItem} key={e[1].src}>
          <img src={`images/${e[1].src}`} alt={`${e[0]}로고`} />
        </li>
      )
    })
  }, [])


  useEffect(() => {
    if (!typo1Intersection.isInterSection) {
      return
    }
    if (!ani1) {
      setAni1(typo1Intersection.isInterSection.isIntersecting)
    } else {
      setAni1("finish")
    }

  }, [typo1Intersection.isInterSection])


  useEffect(() => {
    if (!typo2Intersection.isInterSection) {
      return
    }
    if (!ani2) {
      setAni2(typo2Intersection.isInterSection.isIntersecting)
    } else {
      setAni2("finish")
    }

  }, [typo2Intersection.isInterSection])

  return (
    <section className={styles.page2} id={"about"} ref={ref}>
      <div ref={typo1Ref}
           className={
             classNames(styles.part1, { [styles.animation]: ani1 === true }, { [styles.animation]: ani1 === "finish" })
           }>
        <div>
          <div className={styles.box}>
            <div />
            <img src={`images/page2.png`} alt="" />
          </div>
        </div>
        <div>
          <Typography type={"h1"} className={lan === "ko" ? styles.ko : styles.en1}>
            What is kubee?
          </Typography>
          <Typography type={"h2"} className={lan === "ko" ? styles.ko : styles.en2}>
            {!inner ? t("company_intro1") : t("company_intro1_inner")}
          </Typography>
        </div>
      </div>
      <div
        className={classNames(styles.part2, { [styles.tt]: ani2 === true }, { [styles.animation]: ani1 === "finish" })}
        ref={typo2Ref}>
        <div>
          <Typography type={"h1"} className={lan === "ko" ? styles.ko : styles.en}>
            Who we are?
          </Typography>
          <Typography type={"h2"} className={lan === "ko" ? styles.ko : styles.en2}>
            {!inner ? t("who_we_are1") : t("who_we_are1_inner")}
          </Typography>
        </div>
        <ul className={styles.partners}>
          {renderPartners}
          <li className={classNames(styles.partnersItem, styles.mediaList)}>
            <img src={`images/redbull.svg`} alt="레드불 이미지" />
          </li>
          <li className={classNames(styles.partnersItem, styles.mediaList)}>
            <img src={`images/bibigo.svg`} alt="비비공 이미지" />
          </li>
        </ul>
      </div>
      {/*</div>*/}
    </section>
  )
})

export default Page2