// extracted by mini-css-extract-plugin
export var page3 = "page3-module--page3--bRPX0";
export var animation = "page3-module--animation--YHvfL";
export var container = "page3-module--container--mpYuS";
export var ani = "page3-module--ani--IPZK-";
export var en = "page3-module--en--MLaMa";
export var box = "page3-module--box--ZLCQA";
export var stepItem = "page3-module--stepItem--kbd0H";
export var stepContent0 = "page3-module--stepContent0--i0xNU";
export var stepContent1 = "page3-module--stepContent1--6AW8F";
export var stepContent2 = "page3-module--stepContent2--zhsZ+";
export var enh3_0 = "page3-module--enh3_0--TI-Mv";
export var enh3_1 = "page3-module--enh3_1--uKFm6";
export var enh3_2 = "page3-module--enh3_2--AV6D8";
export var image = "page3-module--image--ITAGi";
export var image0 = "page3-module--image0--GpPLu";
export var image1 = "page3-module--image1--lrnNu";
export var image2 = "page3-module--image2--nimN0";
export var divider = "page3-module--divider--eGBZM";